<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">签到</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="searchcontain">
        <div class="left">
          <div class="contain">
            <div class="maintext">关键字:</div>
            <el-input class="elinput" v-model="form.keyword" placeholder="请输入内容" clearable>
              <i class="el-icon-search el-icon_search" slot="suffix"> </i>
            </el-input>
          </div>

          <div class="contain">
            <div class="maintext">签到日期:</div>
            <el-date-picker v-model="timevalue" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>

          <div class="contain">
            <div class="maintext">签到状态:</div>
            <el-select class="elinput" v-model="form.status" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <el-button class="bt restbt" @click="reset">重置</el-button>
          <el-button class="bt searchbt" @click="search">查询</el-button>
        </div>
      </div>
      <div class="h-tip">
        <div class="h-icon">!</div>
        <div class="h-text">请在小程序中进行签到。</div>
      </div>
      <div class="main-contain">
        <div class="contain">
          <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table"
            :header-cell-style="{
              'font-size': '15px',
              color: '#666666',
              'font-weight': 'bold',
              background: '#F7F7F7',
            }" :row-style="{
              'font-size': '15px',
              color: '#222222',
              'font-weight': '400',
            }">
            <el-table-column prop="start_time" label="签到时间">
              <template slot-scope="{ row }">
                {{ row.start_time | dataformat }}
              </template>
            </el-table-column>
            <el-table-column prop="address" label="签到地点"> </el-table-column>
            <el-table-column prop="status" label="签到状态">
              <template slot-scope="{ row }">
                {{ row.status | typeformat }}
              </template>
            </el-table-column>
          </el-table>
          <div class="elpagination">
            <div class="elpagination-title">共{{ total }}条</div>
            <el-pagination background layout="prev, pager, next" :total="total" :page-size="10"
              :current-page="form.page" @current-change="changePage">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getpracticeattendancelist } from "@/api/practiceattendance";

const typemap = ["未签到", "已签到", "外勤签到", "签到异常", "请假"];

export default {
  name: "mainpage",
  components: {},
  filters: {
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
    typeformat(val) {
      return typemap[val];
    },
  },
  data() {
    return {
      total: 0,
      timevalue: "",
      form: {
        keyword: "",
        star_time: "",
        end_time: "",
        practice_plan_id: "",
        status: "",
        page: 1,
        per_page: 10,
      },

      tableData: [],
      options: [
        {
          value: 0,
          label: "未签到",
        },
        {
          value: 1,
          label: "已签到",
        },
        {
          value: 2,
          label: "外勤签到",
        },
        {
          value: 3,
          label: "签到异常",
        },
        {
          value: 4,
          label: "请假",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.form.practice_plan_id = this.$route.query.plan_id;
    this.getpracticeattendancelist({
      practice_plan_id: this.$route.query.plan_id,
    });
  },
  methods: {
    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 6,
          componentId: "Myinternship",
        },
      });
    },
    todetail() {
      this.$router.push({
        path: "/home/personalcenter/interactiveqadetails",
      });
    },
    tosubmitreport() {
      this.$router.push({
        path: "/home/personalcenter/submitreport",
      });
    },
    revisebt() {
      this.$router.push({
        path: "/home/personalcenter/newleave",
      });
    },
    changePage(val) {
      this.form.page = val;
      this.getpracticeattendancelist(this.form);
    },
    reset() {
      this.form.keyword = "";
      this.form.star_time = "";
      this.form.end_time = "";
      this.timevalue = "";
      this.status = "";
      this.getpracticeattendancelist(this.form);
    },
    search() {
      if (this.timevalue && this.timevalue.length > 0) {
        if (this.timevalue[0]) {
          this.form.star_time = dayjs(this.timevalue[0]).unix();
        }
        if (this.timevalue[1]) {
          this.form.end_time = dayjs(this.timevalue[1]).unix();
        }
      } else {
        this.form.star_time = "";
        this.form.end_time = "";
      }
      this.getpracticeattendancelist(this.form);
    },
    getpracticeattendancelist(params) {
      this.tableData = [];
      getpracticeattendancelist(params)
        .then((response) => {
          if (response.code === 0) {
            this.tableData = response.data.data;
            this.total = response.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  background: #fff;
  min-height: calc(100vh - 240px);
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    padding-top: 10px;
    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
    }
    .retutnbt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    background: #fff;
    border-radius: 4px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .searchcontain {
      height: 56px;
      background: #fcfcfc;
      border: 1px solid #ececec;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .contain {
          margin-right: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .maintext {
            margin-right: 10px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
          }
          .elinput {
            width: 200px;
            ::v-deep .el-input__suffix {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
      .right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .bt {
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #3d84ff;
          color: white;
          padding: 0px;
        }
        .restbt {
          margin-right: 10px;
          background: white;
          color: #3d84ff;
        }
      }
    }
    .h-tip {
      margin-top: 10px;
      height: 26px;
      background: #fffbe6;
      border: 1px solid #ffaa00;
      border-radius: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .h-icon {
        margin-left: 20px;
        margin-right: 10px;
        width: 16px;
        height: 17px;
        line-height: 17px;
        text-align: center;
        border-radius: 50%;
        background: #ffaa00;
        color: white;
      }
      .h-text {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
    .main-contain {
      margin-top: 20px;
      width: 100%;
      .contain {
        width: 100%;
        height: 100%;
        .Table {
          width: 100%;
          .item-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .title {
              margin-left: 10px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }
          .item-middle {
            .title {
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }

          .item-right {
            display: flex;
            justify-content: center;
            align-items: center;
            .bt {
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #0773fc;
              user-select: none;
              cursor: pointer;
            }
            .line2 {
              margin-right: 8px;
              margin-left: 8px;
              height: 14px;
              border-right: 2px solid #cccccc;
            }
          }
        }
        .elpagination {
          margin-top: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          .elpagination-title {
            margin-right: 4px;
          }
        }
      }
    }
  }
}
</style>
